/** @format */

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Form, Input, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../../Helpers/BaseUrlProvider";
import { toStringAsFixed } from "../../Helpers/FrontendHelper";
import ActionTypes from "../../constants/action_types";
import APIEndpoints from "../../constants/api_endpoints";
import {
  CARD_ELEMENT_OPTIONS,
  CHANNEL_PLATFORM,
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
} from "../../constants/definitions";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useSnackbar from "../../hooks/useSnackbar";
import MakePayment from "../../pages/make-payment/MakePayment";
import {
  OrderType,
  PaymentMethod,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import {
  MakePaymentFormValues,
  MakePaymentRequest,
} from "../../types/commonInterfaces";
import { useRetailHelper } from "../../utils/useRetailHelper";

const MakePaymentContainer = () => {
  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();
  const isGuestCheckout = location.state?.isGuestCheckout;
  const [makePaymentForm] = Form.useForm<MakePaymentFormValues>();

  const [voucherCode, setVoucherCode] = useState<string | undefined>();
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const { loginResponse } = useAppSelector((state) => state.authentication);
  const { countires } = useAppSelector((state) => state.common);
  const {
    isLoading,
    error,
    successMessage,
    isCheckVoucherSuccess,
    voucherDiscount,
    isAddDeliveryLocationSuccess,
    addDeliverLocationLoading,
    deliveryAddresses,
    makePaymentLoading,
    isMakePaymentSuccessful,
    isValidateOrderSuccess,
  } = useAppSelector((state) => state.makePayment);
  const [activeOrderType, setActiveOrderType] = useState<OrderType>();
  const activeOrderTypeRef = useRef(activeOrderType);

  useEffect(() => {
    activeOrderTypeRef.current = activeOrderType;
  }, [activeOrderType]);

  const [activePaymentMethod, setActivePaymentMethod] =
    useState<PaymentMethod>();
  const isCreditCardPayment = activePaymentMethod?.identifier == "7";
  const [deliveryAddress, setDeliveryAddress] = useState<any>({});
  const selectedDate = Form.useWatch("pickUpDeliveryDateTime", makePaymentForm);
  const [selectedWeekDay, setSelectedWeekDay] = useState<string>(
    moment().format("dddd").toLowerCase()
  );
  const [finalOrderNote, setFinalOrderNote] = useState<string>("");
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const {
    getTableId,
    isStoreTaxInclusive,
    getVoucherDiscountAmount,
    getDiscountTax,
    getTotalAmountWithTaxOfCart,
    calculateTotalTax,
    getTotalAmountOfCart,
    getDeliveryAmount,
    getDeliveryTax,
    formatDate,
    getCartProducts,
    calculateProductTax,
    clearCartProducts,
    calculateModifierTax,
    createRequestObjectForFilterOptions,
  } = useRetailHelper();

  const onRedeemVoucherHandler = () => {
    if (voucherCode) {
      dispatch({
        type: ActionTypes.REDEEM_VOUCHER_REQUEST,
        payload: {
          StoreId: homePageDataModel?.storeDetails?.id,
          VoucherCode: voucherCode,
          UserId: loginResponse?.userId,
        },
      });
    }
  };

  useEffect(() => {
    if (deliveryAddress.deliveryLocation) {
      dispatch({
        type: ActionTypes.ADD_DELIVERY_LOCATION_REQUEST,
        payload: {
          Id: deliveryAddress.id ?? "",
          UserId: loginResponse?.userId,
          StoreId: homePageDataModel?.storeDetails?.id,
          Latitude: String(deliveryAddress.latitude),
          Longitude: String(deliveryAddress.longitude),
          DeliveryLocation: String(deliveryAddress.deliveryLocation),
        },
      });
    }
  }, [deliveryAddress]);

  const onAddDeliveryLocationSubmit = () => {
    if (deliveryAddress.deliveryLocation) {
      dispatch({
        type: ActionTypes.ADD_DELIVERY_LOCATION_REQUEST,
        payload: {
          Id: deliveryAddress.id ?? "",
          UserId: loginResponse?.userId,
          StoreId: homePageDataModel?.storeDetails?.id,
          Latitude: String(deliveryAddress.latitude),
          Longitude: String(deliveryAddress.longitude),
          DeliveryLocation: String(deliveryAddress.deliveryLocation),
        },
      });
    }
  };

  const validateFormAndOrder = async () => {
    try {
      const payRequest = await getMakePaymentRequest();
      await makePaymentForm.validateFields();
      await API.post(APIEndpoints.VALIDATE_ORDER, payRequest);
      return true;
    } catch (errorInfo) {
      return false;
    }
  };
  console.log("--active order tyeppppe", activeOrderType);

  // const getMakePaymentRequest = async () => {
  //   console.log("--active order tye", activeOrderType);
  //   const values = makePaymentForm.getFieldsValue();
  //   const requiredKeys = [
  //     // "recieverCountryPhoneNumberPrefix",
  //     // "recieverCountryId",
  //     "recieverName",
  //     // "recieverEmail",
  //     // "recieverPhoneNumber",
  //     // "recieverPostalCode",
  //     // "message",
  //     // "sendEmailToReceiver",
  //   ];

  //   const hasAnyRequiredKey = requiredKeys.some((key) => key in values);
  //   if (
  //     activeOrderType?.identifier == "2" &&
  //     !deliveryAddress?.deliveryLocation
  //   ) {
  //     message.error("Please Choose Delivery Address !");
  //     return;
  //   }

  //   let stripePaymentMethod = null;
  //   if (isCreditCardPayment) {
  //     if (!stripe || !elements) {
  //       message.error("Stripe has not been loaded !");
  //       return;
  //     }

  //     const cardElement = elements.getElement(CardNumberElement);

  //     if (!cardElement) {
  //       message.error("Card has not been loaded !");
  //       return;
  //     }
  //     setStripeLoading(true);

  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: "card",
  //       card: cardElement,
  //       billing_details: {
  //         email: makePaymentForm.getFieldValue("stripeEmail"),
  //       },
  //     });
  //     stripePaymentMethod = paymentMethod;
  //     setStripeLoading(false);

  //     if (error) {
  //       message.error(error.message);
  //       return;
  //     }
  //   }

  //   const makePaymentRequest = {
  //     IsGuestCheckout: isGuestCheckout,
  //     StoreId: homePageDataModel?.storeDetails?.id,
  //     OrderTypeId: activeOrderType?.id,
  //     UserId: isGuestCheckout ? "" : loginResponse?.userId,
  //     ChannelPlatform: CHANNEL_PLATFORM,
  //     TableId: getTableId(),
  //     Description: finalOrderNote,
  //     DiscountAmount: toStringAsFixed(
  //       isStoreTaxInclusive()
  //         ? getVoucherDiscountAmount() - getDiscountTax()
  //         : getVoucherDiscountAmount()
  //     ),
  //     DiscountPercentage: isCheckVoucherSuccess ? voucherDiscount : "",
  //     DiscountAmountWithTax: toStringAsFixed(
  //       isStoreTaxInclusive()
  //         ? getVoucherDiscountAmount()
  //         : getVoucherDiscountAmount() + getDiscountTax()
  //     ),
  //     DeliveryAddress: deliveryAddress?.deliveryLocation ?? "",
  //     TotalAmount: toStringAsFixed(getTotalAmountWithTaxOfCart()),
  //     DeliveryAmount: toStringAsFixed(
  //       isStoreTaxInclusive()
  //         ? getDeliveryAmount() - getDeliveryTax()
  //         : getDeliveryAmount()
  //     ),
  //     DeliveryAmountWithTax: toStringAsFixed(
  //       isStoreTaxInclusive()
  //         ? getDeliveryAmount()
  //         : getDeliveryAmount() + getDeliveryTax()
  //     ),
  //     TotalTaxAmount: toStringAsFixed(
  //       calculateTotalTax() + getDeliveryTax() - getDiscountTax()
  //     ),
  //     TotalWithoutTaxAmount: toStringAsFixed(
  //       isStoreTaxInclusive()
  //         ? getTotalAmountOfCart() - calculateTotalTax()
  //         : getTotalAmountOfCart()
  //     ),
  //     VoucherRequestViewModel: {
  //       VoucherCode: isCheckVoucherSuccess ? voucherCode : "",
  //     },
  //     PickUpDeliveryDateTime:
  //       formatDate(values.pickUpDeliveryDateTime)?.split(" ")?.[0] +
  //       " " +
  //       (activeOrderType?.identifier != "2"
  //         ? values.time
  //           ? values.time
  //           : "00:00:00"
  //         : "00:00:00"),
  //     PaymentMethodId: homePageDataModel?.storeDetails?.paymentMethods.find(
  //       (item) => item.identifier == "7"
  //     )?.id,
  //     PaymentMethodStripeId: stripePaymentMethod ? stripePaymentMethod.id : "",
  //     // PaymentIntentId

  //     // CreditCardRequestModel: {
  //     //   NameOnCard: values.name,
  //     //   CardNumber: values.cardNumber,
  //     //   CVCNumber: values.cvc,
  //     //   ExpiryMonth: values.expiry?.split("/")[0],
  //     //   ExpiryYear: values.expiry?.split("/")[1],
  //     // },

  //     CustomerRequestModel: isGuestCheckout
  //       ? {
  //           Name: values.fullName,
  //           Email: values.email,
  //           PhoneNumber: values.phoneNumber,
  //           CountryId: values.countryId,
  //           CountryPhoneNumberPrefixId: values.countryPhoneNumberPrefixId,
  //           PostalCode: values.postalCode,
  //         }
  //       : null,
  //     ReceiverRequestModel:
  //       hasAnyRequiredKey && values.recieverName
  //         ? {
  //             Id: "",
  //             CountryPhoneNumberPrefixId:
  //               values.recieverCountryPhoneNumberPrefixId ?? "",
  //             CountryId: values.recieverCountryId ?? "",
  //             Name: values.recieverName ?? "",
  //             // Email: values.recieverEmail ?? "",
  //             PhoneNumber: values.recieverPhoneNumber ?? "",
  //             PostalCode: values.recieverPostalCode ?? "",
  //             Message: values.message ?? "",
  //             SendEmailToReceiver: false,
  //           }
  //         : null,
  //     OrderDetailsRequestModel: getCartProducts().map((item, index) => {
  //       return {
  //         ProductId: item.productId,
  //         ProductPrice: toStringAsFixed(item.actualPrice),
  //         ProductVariationId: item.productVariationId,
  //         Description: item.description,
  //         Quantity: item.quantity.toString(),
  //         Total: toStringAsFixed(item.quantity * item.price),
  //         TotalTax: toStringAsFixed(calculateProductTax(item)),
  //         OrderItemsSelectOptionsRequestModel:
  //           createRequestObjectForFilterOptions(
  //             item.productFilterTypeFilterOptions ?? []
  //           ),
  //         DiscountWithoutTax: toStringAsFixed(0),
  //         OrderItemsPriceModifierRequestModel: item.priceModifiers?.map(
  //           (modifier) => {
  //             return {
  //               ModifierName: modifier.name,
  //               ModifierPrice: modifier.price,
  //               TotalModifierPrice: toStringAsFixed(
  //                 parseFloat(modifier.price) * item.quantity
  //               ),
  //               Quantity: toStringAsFixed(item.quantity),
  //               PriceVariationModifierId: modifier.id,
  //               // Changing this as Cart Product
  //               TotalTax: toStringAsFixed(
  //                 calculateModifierTax(
  //                   parseFloat(modifier.price),
  //                   item.quantity,
  //                   Number(item.tax)
  //                 )
  //               ),
  //             };
  //           }
  //         ),
  //       };
  //     }),
  //   };

  //   return makePaymentRequest as MakePaymentRequest;
  // };
  const getMakePaymentRequest = useCallback(async () => {
    console.log("--active order type", activeOrderTypeRef.current);
    const values = makePaymentForm.getFieldsValue();
    const requiredKeys = ["recieverName"];

    const hasAnyRequiredKey = requiredKeys.some((key) => key in values);
    if (
      activeOrderTypeRef?.current?.identifier === "2" &&
      !deliveryAddress?.deliveryLocation
    ) {
      message.error("Please Choose Delivery Address!");
      return;
    }

    let stripePaymentMethod = null;
    if (isCreditCardPayment) {
      if (!stripe || !elements) {
        message.error("Stripe has not been loaded!");
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        message.error("Card has not been loaded!");
        return;
      }
      setStripeLoading(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: makePaymentForm.getFieldValue("stripeEmail"),
        },
      });
      stripePaymentMethod = paymentMethod;
      setStripeLoading(false);

      if (error) {
        message.error(error.message);
        return;
      }
    }

    const makePaymentRequest: MakePaymentRequest = {
      IsGuestCheckout: isGuestCheckout,
      StoreId: homePageDataModel?.storeDetails?.id,
      OrderTypeId: activeOrderTypeRef?.current?.id,
      UserId: isGuestCheckout ? "" : loginResponse?.userId,
      ChannelPlatform: CHANNEL_PLATFORM,
      TableId: getTableId(),
      Description: finalOrderNote,
      DiscountAmount: toStringAsFixed(
        isStoreTaxInclusive()
          ? getVoucherDiscountAmount() - getDiscountTax()
          : getVoucherDiscountAmount()
      ),
      DiscountPercentage: isCheckVoucherSuccess ? voucherDiscount : "",
      DiscountAmountWithTax: toStringAsFixed(
        isStoreTaxInclusive()
          ? getVoucherDiscountAmount()
          : getVoucherDiscountAmount() + getDiscountTax()
      ),
      DeliveryAddress: deliveryAddress?.deliveryLocation ?? "",
      TotalAmount: toStringAsFixed(getTotalAmountWithTaxOfCart()),
      DeliveryAmount: toStringAsFixed(
        isStoreTaxInclusive()
          ? getDeliveryAmount() - getDeliveryTax()
          : getDeliveryAmount()
      ),
      DeliveryAmountWithTax: toStringAsFixed(
        isStoreTaxInclusive()
          ? getDeliveryAmount()
          : getDeliveryAmount() + getDeliveryTax()
      ),
      TotalTaxAmount: toStringAsFixed(
        calculateTotalTax() + getDeliveryTax() - getDiscountTax()
      ),
      TotalWithoutTaxAmount: toStringAsFixed(
        isStoreTaxInclusive()
          ? getTotalAmountOfCart() - calculateTotalTax()
          : getTotalAmountOfCart()
      ),
      VoucherRequestViewModel: {
        VoucherCode: isCheckVoucherSuccess ? voucherCode : "",
      },
      PickUpDeliveryDateTime:
        formatDate(values.pickUpDeliveryDateTime)?.split(" ")?.[0] +
        " " +
        (activeOrderTypeRef?.current?.identifier !== "2"
          ? values.time
            ? values.time
            : "00:00:00"
          : "00:00:00"),
      PaymentMethodId: activePaymentMethod?.id,
      PaymentMethodStripeId: stripePaymentMethod ? stripePaymentMethod.id : "",
      CustomerRequestModel: isGuestCheckout
        ? {
            Name: values.fullName,
            Email: values.email,
            PhoneNumber: values.phoneNumber,
            CountryId: values.countryId,
            CountryPhoneNumberPrefixId: values.countryPhoneNumberPrefixId,
            PostalCode: values.postalCode,
          }
        : null,
      ReceiverRequestModel:
        hasAnyRequiredKey && values.recieverName
          ? {
              Id: "",
              CountryPhoneNumberPrefixId:
                values.recieverCountryPhoneNumberPrefixId ?? "",
              CountryId: values.recieverCountryId ?? "",
              Name: values.recieverName ?? "",
              PhoneNumber: values.recieverPhoneNumber ?? "",
              PostalCode: values.recieverPostalCode ?? "",
              Message: values.message ?? "",
              SendEmailToReceiver: false,
            }
          : null,
      OrderDetailsRequestModel: getCartProducts().map((item) => {
        return {
          ProductId: item.productId,
          ProductPrice: toStringAsFixed(item.actualPrice),
          ProductVariationId: item.productVariationId,
          Description: item.description,
          Quantity: item.quantity.toString(),
          Total: toStringAsFixed(item.quantity * item.price),
          TotalTax: toStringAsFixed(calculateProductTax(item)),
          OrderItemsSelectOptionsRequestModel:
            createRequestObjectForFilterOptions(
              item.productFilterTypeFilterOptions ?? []
            ),
          DiscountWithoutTax: toStringAsFixed(0),
          OrderItemsPriceModifierRequestModel: item.priceModifiers?.map(
            (modifier) => {
              return {
                ModifierName: modifier.name,
                ModifierPrice: modifier.price,
                TotalModifierPrice: toStringAsFixed(
                  parseFloat(modifier.price) * item.quantity
                ),
                Quantity: toStringAsFixed(item.quantity),
                PriceVariationModifierId: modifier.id,
                TotalTax: toStringAsFixed(
                  calculateModifierTax(
                    parseFloat(modifier.price),
                    item.quantity,
                    Number(item.tax)
                  )
                ),
              };
            }
          ),
        };
      }),
    } as MakePaymentRequest;

    return makePaymentRequest;
  }, [
    makePaymentForm,
    activeOrderType,
    deliveryAddress,
    isCreditCardPayment,
    stripe,
    elements,
    homePageDataModel,
    isGuestCheckout,
    loginResponse,
    CHANNEL_PLATFORM,
    getTableId,
    finalOrderNote,
    getVoucherDiscountAmount,
    getDiscountTax,
    isStoreTaxInclusive,
    isCheckVoucherSuccess,
    voucherDiscount,
    getTotalAmountWithTaxOfCart,
    getDeliveryAmount,
    getDeliveryTax,
    calculateTotalTax,
    getTotalAmountOfCart,
    voucherCode,
    formatDate,
    getCartProducts,
    createRequestObjectForFilterOptions,
    CardNumberElement,
    message,
    setStripeLoading,
    stripeLoading,
  ]);
  const onMakePaymentFormSubmitHandler = async (
    values: MakePaymentFormValues
  ) => {
    const payRequest = await getMakePaymentRequest();
    dispatch({
      type: ActionTypes.VALIDATE_ORDER_REQUEST,
      payload: payRequest,
    });
  };

  useEffect(() => {
    const fetchPaymentRequest = async () => {
      if (isValidateOrderSuccess) {
        dispatch({ type: ActionTypes.CLEAR_VALIDATE_ORDER });
        try {
          const payRequest = await getMakePaymentRequest();
          dispatch({
            type: ActionTypes.MAKE_PAYMENT_REQUEST,
            payload: payRequest,
          });
        } catch (error) {
          console.error("Failed to fetch payment request:", error);
        }
      }
    };

    fetchPaymentRequest();
  }, [isValidateOrderSuccess, dispatch]);

  useEffect(() => {
    if (homePageDataModel) {
      setActiveOrderType(homePageDataModel.storeDetails?.orderTypes[0]);
      setActivePaymentMethod(
        homePageDataModel.storeDetails?.paymentMethods?.[0]
      );
      makePaymentForm.setFields([
        {
          name: "pickUpDeliveryDateTime",
          value: dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD"),
        },
      ]);
    }
  }, [homePageDataModel]);

  useEffect(() => {
    if (activeOrderType?.identifier == "2" && !isGuestCheckout) {
      dispatch({
        type: ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_REQUEST,
        payload: {
          UserId: loginResponse?.userId,
        },
      });
    }
    dispatch({
      type: "CLEAR_DELIVERY_STATE",
    });
    setDeliveryAddress({});
  }, [activeOrderType]);

  useEffect(() => {
    if (isAddDeliveryLocationSuccess && !isGuestCheckout) {
      dispatch({
        type: ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_REQUEST,
        payload: {
          UserId: loginResponse?.userId,
        },
      });
    }
  }, [isAddDeliveryLocationSuccess]);

  useEffect(() => {
    if (isMakePaymentSuccessful) {
      makePaymentForm.resetFields();
      setDeliveryAddress({});
      setVoucherCode("");
      clearCartProducts();
      setFinalOrderNote("");
      dispatch({
        type: "CLEAR_DELIVERY_STATE",
      });
    }
  }, [isMakePaymentSuccessful]);

  return (
    <>
      <MakePayment
        voucherCode={voucherCode}
        setVoucherCode={setVoucherCode}
        onRedeemVoucherHandler={onRedeemVoucherHandler}
        onMakePaymentFormSubmitHandler={onMakePaymentFormSubmitHandler}
        makePaymentForm={makePaymentForm}
        homePageDataModel={homePageDataModel}
        activeOrderType={activeOrderType}
        setActiveOrderType={setActiveOrderType}
        activePaymentMethod={activePaymentMethod}
        setActivePaymentMethod={setActivePaymentMethod}
        isLoading={isLoading}
        isCheckVoucherSuccess={isCheckVoucherSuccess}
        voucherDiscount={voucherDiscount}
        deliveryAddress={deliveryAddress}
        setDeliveryAddress={setDeliveryAddress}
        onAddDeliveryLocationSubmit={onAddDeliveryLocationSubmit}
        addDeliverLocationLoading={addDeliverLocationLoading}
        deliveryAddresses={deliveryAddresses}
        makePaymentLoading={makePaymentLoading}
        isMakePaymentSuccessful={isMakePaymentSuccessful}
        selectedDate={selectedDate}
        selectedWeekDay={selectedWeekDay}
        setSelectedWeekDay={setSelectedWeekDay}
        finalOrderNote={finalOrderNote}
        setFinalOrderNote={setFinalOrderNote}
        isGuestCheckout={isGuestCheckout}
        countries={countires}
        cardElement={
          <>
            <div className="row">
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name="stripeEmail"
                  label="Email Address"
                  rules={[
                    {
                      required: isCreditCardPayment,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                    ...(isCreditCardPayment
                      ? [
                          {
                            type: "email" as const, // explicitly specify that "email" is a RuleType
                            message: EMAIL_VALIDATION_MESSAGE,
                          },
                        ]
                      : []),
                  ]}
                >
                  <Input placeholder="Please enter email address" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"cardNumber"}
                  label="Card Number"
                  rules={[
                    {
                      required: isCreditCardPayment && true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                  ]}
                >
                  <CardNumberElement
                    className="stripe-input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"expiryDate"}
                  label="Expiry Date"
                  rules={[
                    {
                      required: isCreditCardPayment && true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                  ]}
                >
                  <CardExpiryElement
                    className="stripe-input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                {" "}
                <Form.Item
                  name={"CVC"}
                  label="CVC"
                  rules={[
                    {
                      required: isCreditCardPayment && true,
                      message: EMPTY_FIELD_MESSAGE,
                    },
                  ]}
                >
                  <CardCvcElement
                    className="stripe-input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </Form.Item>
              </div>
            </div>
          </>
        }
        stripeLoading={stripeLoading}
        getMakePaymentRequest={getMakePaymentRequest}
      />
    </>
  );
};

export default MakePaymentContainer;
