/** @format */

import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { createAsyncAction } from "typesafe-actions";
import { API } from "../Helpers/BaseUrlProvider";
import ActionTypes from "../constants/action_types";
import APIEndpoints from "../constants/api_endpoints";

// Initialize Store Epic
// This epic initializes the store when the app is loaded.
const INITIALIZE_STORE = createAsyncAction(
  ActionTypes.INITIALIZE_STORE_REQUEST,
  ActionTypes.INITIALIZE_STORE_SUCCESS,
  ActionTypes.INITIALIZE_STORE_FAILURE
)<string, any, string>();

export const initializeStoreEpic: Epic = (action$) =>
  action$.pipe(
    ofType(INITIALIZE_STORE.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.INITIALIZE_STORE, action.payload)).pipe(
        mergeMap((response) => of(INITIALIZE_STORE.success(response))),
        catchError((error) => of(INITIALIZE_STORE.failure(error)))
      )
    )
  );

// Get Landing Page Data Epic
// This epic fetches data for the landing page.
const GET_LANDING_PAGE_DATA = createAsyncAction(
  ActionTypes.GET_LANDING_PAGE_DATA_REQUEST,
  ActionTypes.GET_LANDING_PAGE_DATA_SUCCESS,
  ActionTypes.GET_LANDING_PAGE_DATA_FAILURE
)<string, any, string>();

export const getLandingPagedataEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_LANDING_PAGE_DATA.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_LANDING_PAGE_DATA, action.payload, {
          headers: {
            StoreId: action.payload?.StoreId,
            "Cache-Control": "max-age=3600",
          },
        })
      ).pipe(
        mergeMap((response) => of(GET_LANDING_PAGE_DATA.success(response))),
        catchError((error) => of(GET_LANDING_PAGE_DATA.failure(error)))
      )
    )
  );

const GET_STORE_DETAILS = createAsyncAction(
  ActionTypes.GET_STORE_DETAILS_REQUEST,
  ActionTypes.GET_STORE_DETAILS_SUCCESS,
  ActionTypes.GET_STORE_DETAILS_FAILURE
)<string, any, string>();

export const getStoreDetailsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_STORE_DETAILS.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_STORE_DETAILS, action.payload, {
          headers: {
            StoreId: action.payload?.StoreId,
            "Cache-Control": "max-age=3600",
          },
        })
      ).pipe(
        mergeMap((response) => of(GET_STORE_DETAILS.success(response))),
        catchError((error) => of(GET_STORE_DETAILS.failure(error)))
      )
    )
  );

const GET_ALL_CATEGORIES = createAsyncAction(
  ActionTypes.GET_ALL_CATEGORIES_REQUEST,
  ActionTypes.GET_ALL_CATEGORIES_SUCCESS,
  ActionTypes.GET_ALL_GIFT_CARDS_FAILURE
)<string, any, string>();

export const getAllCategoriesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_CATEGORIES.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_ALL_CATEGORIES, action.payload, {
          headers: {
            StoreId: action.payload?.StoreId,
            "Cache-Control": "max-age=3600",
          },
        })
      ).pipe(
        mergeMap((response) => of(GET_ALL_CATEGORIES.success(response))),
        catchError((error) => of(GET_ALL_CATEGORIES.failure(error)))
      )
    )
  );

// Get Product Details Epic
// This epic fetches details of a specific product.
const GET_PRODUCT_DETAILS = createAsyncAction(
  ActionTypes.GET_PRODUCT_DETAILS_REQUEST,
  ActionTypes.GET_PRODUCT_DETAILS_SUCCESS,
  ActionTypes.GET_PRODUCT_DETAILS_FAILURE
)<string, any, string>();

export const getProductDetailsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_PRODUCT_DETAILS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_PRODUCT_DETAILS, action.payload)).pipe(
        mergeMap((response) => of(GET_PRODUCT_DETAILS.success(response))),
        catchError((error) => of(GET_PRODUCT_DETAILS.failure(error)))
      )
    )
  );

// Get Product Details Epic
// This epic fetches details of a specific product.
const GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION = createAsyncAction(
  ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_REQUEST,
  ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_SUCCESS,
  ActionTypes.GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION_FAILURE
)<string, any, string>();

export const getProductDetailsCopyEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_PRODUCT_DETAILS, action.payload)).pipe(
        mergeMap((response) =>
          of(GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION.success(response))
        ),
        catchError((error) =>
          of(GET_PRODUCT_DETAILS_COPY_WITHOUT_REDIRECTION.failure(error))
        )
      )
    )
  );

// Get All Products Epic
// This epic fetches all products from the server.
const GET_ALL_PRODUCTS = createAsyncAction(
  ActionTypes.GET_ALL_PRODUCTS_REQUEST,
  ActionTypes.GET_ALL_PRODUCTS_SUCCESS,
  ActionTypes.GET_ALL_PRODUCTS_FAILURE
)<string, any, string>();

export const getAllProductsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_PRODUCTS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_ALL_PRODUCTS, action.payload)).pipe(
        mergeMap((response) => of(GET_ALL_PRODUCTS.success(response))),
        catchError((error) => of(GET_ALL_PRODUCTS.failure(error)))
      )
    )
  );

// Search Products Epic
// This epic performs a search for products based on the provided search criteria.
const SEARCH_PRODUCTS = createAsyncAction(
  ActionTypes.SEARCH_PRODUCTS_REQUEST,
  ActionTypes.SEARCH_PRODUCTS_SUCCESS,
  ActionTypes.SEARCH_PRODUCTS_FAILURE
)<string, any, string>();

export const searchProductsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(SEARCH_PRODUCTS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.SEARCH_PRODUCTS, action.payload)).pipe(
        mergeMap((response) => of(SEARCH_PRODUCTS.success(response))),
        catchError((error) => of(SEARCH_PRODUCTS.failure(error)))
      )
    )
  );

// Authenticate User Epic
// This epic handles user authentication (login).
const AUTHENTICATE_USER = createAsyncAction(
  ActionTypes.AUTHENTICATE_USER_REQUEST,
  ActionTypes.AUTHENTICATE_USER_SUCCESS,
  ActionTypes.AUTHENTICATE_USER_FAILURE
)<string, any, string>();

export const authenticateUserEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AUTHENTICATE_USER.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.LOGIN, action.payload)).pipe(
        mergeMap((response) => of(AUTHENTICATE_USER.success(response))),
        catchError((error) => of(AUTHENTICATE_USER.failure(error)))
      )
    )
  );
// Send OTP Epic
// This epic sends the OTP (one-time verification code) for email verification.
const SEND_OTP = createAsyncAction(
  ActionTypes.SEND_OTP_REQUEST,
  ActionTypes.SEND_OTP_SUCCESS,
  ActionTypes.SEND_OTP_FAILURE
)<string, any, string>();

export const sendOTPEpic: Epic = (action$) =>
  action$.pipe(
    ofType(SEND_OTP.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.SEND_OTP, action.payload)).pipe(
        mergeMap((response) => of(SEND_OTP.success(response))),
        catchError((error) => of(SEND_OTP.failure(error)))
      )
    )
  );
const SEND_OTP_RESEND = createAsyncAction(
  ActionTypes.SEND_OTP_RESEND_REQUEST,
  ActionTypes.SEND_OTP_RESEND_SUCCESS,
  ActionTypes.SEND_OTP_RESEND_FAILURE
)<string, any, string>();

export const sendOTPResendEpic: Epic = (action$) =>
  action$.pipe(
    ofType(SEND_OTP_RESEND.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.SEND_OTP, action.payload)).pipe(
        mergeMap((response) => of(SEND_OTP_RESEND.success(response))),
        catchError((error) => of(SEND_OTP_RESEND.failure(error)))
      )
    )
  );

// Register User Epic
// This epic handles user registration.
const REGISTER_USER = createAsyncAction(
  ActionTypes.REGSITER_USER_REQUEST,
  ActionTypes.REGSITER_USER_SUCCESS,
  ActionTypes.REGSITER_USER_FAILURE
)<string, any, string>();

export const registerUserEpic: Epic = (action$) =>
  action$.pipe(
    ofType(REGISTER_USER.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.REGISTER_USER, action.payload)).pipe(
        mergeMap((response) => of(REGISTER_USER.success(response))),
        catchError((error) => of(REGISTER_USER.failure(error)))
      )
    )
  );

// Forget Password Epic
// This epic handles the process of resetting a user's password.
const FORGET_PASSWORD = createAsyncAction(
  ActionTypes.RESET_PASSWORD_REQUEST,
  ActionTypes.RESET_PASSWORD_SUCCESS,
  ActionTypes.RESET_PASSWORD_FAILURE
)<string, any, string>();

export const forgetPasswordEpic: Epic = (action$) =>
  action$.pipe(
    ofType(FORGET_PASSWORD.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.FORGET_PASSWORD, action.payload)).pipe(
        mergeMap((response) => of(FORGET_PASSWORD.success(response))),
        catchError((error) => of(FORGET_PASSWORD.failure(error)))
      )
    )
  );
// Forget Password Epic
// This epic handles the process of resetting a user's password.
const CHANGE_PASSWORD = createAsyncAction(
  ActionTypes.CHANGE_PASSWORD_REQUEST,
  ActionTypes.CHANGE_PASSWORD_SUCCESS,
  ActionTypes.CHANGE_PASSWORD_FAILURE
)<string, any, string>();

export const changePasswordEpic: Epic = (action$) =>
  action$.pipe(
    ofType(CHANGE_PASSWORD.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.CHANGE_PASSWORD, action.payload)).pipe(
        mergeMap((response) => of(CHANGE_PASSWORD.success(response))),
        catchError((error) => of(CHANGE_PASSWORD.failure(error)))
      )
    )
  );

const CHANGE_PASSWORD_PROFILE = createAsyncAction(
  ActionTypes.CHANGE_PASSWORD_PROFILE_REQUEST,
  ActionTypes.CHANGE_PASSWORD_PROFILE_SUCCESS,
  ActionTypes.CHANGE_PASSWORD_PROFILE_FAILURE
)<string, any, string>();

export const changePasswordProfileEpic: Epic = (action$) =>
  action$.pipe(
    ofType(CHANGE_PASSWORD_PROFILE.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.CHANGE_PASSWORD_PROFILE, action.payload)).pipe(
        mergeMap((response) => of(CHANGE_PASSWORD_PROFILE.success(response))),
        catchError((error) => of(CHANGE_PASSWORD_PROFILE.failure(error)))
      )
    )
  );
// List Countries Epic
// This epic fetches the list of countries.
const LIST_COUNTRIES = createAsyncAction(
  ActionTypes.LIST_COUNTRIES_REQUEST,
  ActionTypes.LIST_COUNTRIES_SUCCESS,
  ActionTypes.LIST_COUNTRIES_FAILURE
)<string, any, string>();

export const listCountiresEpic: Epic = (action$) =>
  action$.pipe(
    ofType(LIST_COUNTRIES.request),
    mergeMap((action) =>
      from(API.get(APIEndpoints.LIST_COUNTRIES)).pipe(
        mergeMap((response) => of(LIST_COUNTRIES.success(response))),
        catchError((error) => of(LIST_COUNTRIES.failure(error)))
      )
    )
  );
// Get All Gift Cards Epic
// This epic fetches all gift cards data.
const GET_ALL_GIFT_CARDS = createAsyncAction(
  ActionTypes.GET_ALL_GIFT_CARDS_REQUEST,
  ActionTypes.GET_ALL_GIFT_CARDS_SUCCESS,
  ActionTypes.GET_ALL_GIFT_CARDS_FAILURE
)<string, any, string>();

export const getAllGiftCardsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_GIFT_CARDS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_ALL_GIFT_CARDS, action.payload)).pipe(
        mergeMap((response) => of(GET_ALL_GIFT_CARDS.success(response))),
        catchError((error) => of(GET_ALL_GIFT_CARDS.failure(error)))
      )
    )
  );

// Check Gift Card Balance Epic
// This epic checks the balance of a gift card.
const CHECK_GIFT_CARD_BALANCE = createAsyncAction(
  ActionTypes.CHECK_GIFT_CARD_BALANCE_REQUEST,
  ActionTypes.CHECK_GIFT_CARD_BALANCE_SUCCESS,
  ActionTypes.CHECK_GIFT_CARD_BALANCE_FAILURE
)<string, any, string>();

export const checkGiftCardBalanceEpic: Epic = (action$) =>
  action$.pipe(
    ofType(CHECK_GIFT_CARD_BALANCE.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.CHECK_GIFT_CARD_BALANCE, action.payload)).pipe(
        mergeMap((response) => of(CHECK_GIFT_CARD_BALANCE.success(response))),
        catchError((error) => of(CHECK_GIFT_CARD_BALANCE.failure(error)))
      )
    )
  );

// Send Gift Cards Epic
// This epic sends gift cards to recipients.
const SEND_GIFT_CARDS = createAsyncAction(
  ActionTypes.SEND_GIFT_CARDS_REQUEST,
  ActionTypes.SEND_GIFT_CARDS_SUCCESS,
  ActionTypes.SEND_GIFT_CARDS_FAILURE
)<string, any, string>();

export const sendGiftCardEpic: Epic = (action$) =>
  action$.pipe(
    ofType(SEND_GIFT_CARDS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.SEND_GIFT_CARDS, action.payload)).pipe(
        mergeMap((response) => of(SEND_GIFT_CARDS.success(response))),
        catchError((error) => of(SEND_GIFT_CARDS.failure(error)))
      )
    )
  );
const REDEEM_VOUCHER = createAsyncAction(
  ActionTypes.REDEEM_VOUCHER_REQUEST,
  ActionTypes.REDEEM_VOUCHER_SUCCESS,
  ActionTypes.REDEEM_VOUCHER_FAILURE
)<string, any, string>();

export const redeemVoucherEpic: Epic = (action$) =>
  action$.pipe(
    ofType(REDEEM_VOUCHER.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.REDEEM_VOUCHER, action.payload)).pipe(
        mergeMap((response) => of(REDEEM_VOUCHER.success(response))),
        catchError((error) => of(REDEEM_VOUCHER.failure(error)))
      )
    )
  );
const ADD_DELIVERY_LOCATION = createAsyncAction(
  ActionTypes.ADD_DELIVERY_LOCATION_REQUEST,
  ActionTypes.ADD_DELIVERY_LOCATION_SUCCESS,
  ActionTypes.ADD_DELIVERY_LOCATION_FAILURE
)<string, any, string>();

export const addDeliveryLocationEpic: Epic = (action$) =>
  action$.pipe(
    ofType(ADD_DELIVERY_LOCATION.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.ADD_DELIVERY_LOCATION, action.payload)).pipe(
        mergeMap((response) => of(ADD_DELIVERY_LOCATION.success(response))),
        catchError((error) => of(ADD_DELIVERY_LOCATION.failure(error)))
      )
    )
  );
const GET_DELIVERY_LOCATIONS_OF_USER = createAsyncAction(
  ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_REQUEST,
  ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_SUCCESS,
  ActionTypes.GET_DELIVERY_LOCATIONS_OF_USER_FAILURE
)<string, any, string>();

export const getDeliveryLocationOfUserEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_DELIVERY_LOCATIONS_OF_USER.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_DELIVERY_LOCATIONS_OF_USER, action.payload)
      ).pipe(
        mergeMap((response) =>
          of(GET_DELIVERY_LOCATIONS_OF_USER.success(response))
        ),
        catchError((error) => of(GET_DELIVERY_LOCATIONS_OF_USER.failure(error)))
      )
    )
  );
const MAKE_PAYMENT = createAsyncAction(
  ActionTypes.MAKE_PAYMENT_REQUEST,
  ActionTypes.MAKE_PAYMENT_SUCCESS,
  ActionTypes.MAKE_PAYMENT_FAILURE
)<string, any, string>();

export const makePaymentEpic: Epic = (action$) =>
  action$.pipe(
    ofType(MAKE_PAYMENT.request),
    mergeMap((action) =>
      from(
        API.post(
          action.payload.IsGuestCheckout
            ? APIEndpoints.MAKE_PAYMENT_GUEST
            : APIEndpoints.MAKE_PAYMENT,
          action.payload
        )
      ).pipe(
        mergeMap((response) => of(MAKE_PAYMENT.success(response))),
        catchError((error) => of(MAKE_PAYMENT.failure(error)))
      )
    )
  );

const VALIDATE_ORDER = createAsyncAction(
  ActionTypes.VALIDATE_ORDER_REQUEST,
  ActionTypes.VALIDATE_ORDER_SUCCESS,
  ActionTypes.VALIDATE_ORDER_FAILURE
)<string, any, string>();

export const validateOrderEpic: Epic = (action$) =>
  action$.pipe(
    ofType(VALIDATE_ORDER.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.VALIDATE_ORDER, action.payload)).pipe(
        mergeMap((response) => of(VALIDATE_ORDER.success(response))),
        catchError((error) => of(VALIDATE_ORDER.failure(error)))
      )
    )
  );
const GET_PROFILE_DETAIL = createAsyncAction(
  ActionTypes.GET_PROFILE_DETAILS_REQUEST,
  ActionTypes.GET_PROFILE_DETAILS_SUCCESS,
  ActionTypes.GET_PROFILE_DETAILS_FAILURE
)<string, any, string>();

export const getProfileDetailEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_PROFILE_DETAIL.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_PROFILE_DETAIL, action.payload)).pipe(
        mergeMap((response) => of(GET_PROFILE_DETAIL.success(response))),
        catchError((error) => of(GET_PROFILE_DETAIL.failure(error)))
      )
    )
  );
const UPDATE_PROFILE_DETAIL = createAsyncAction(
  ActionTypes.UPDATE_PROFILE_DETAILS_REQUEST,
  ActionTypes.UPDATE_PROFILE_DETAILS_SUCCESS,
  ActionTypes.UPDATE_PROFILE_DETAILS_FAILURE
)<string, any, string>();

export const updateProfileDetailEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROFILE_DETAIL.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.UPDATE_PROFILE_DETAIL, action.payload)).pipe(
        mergeMap((response) => of(UPDATE_PROFILE_DETAIL.success(response))),
        catchError((error) => of(UPDATE_PROFILE_DETAIL.failure(error)))
      )
    )
  );
const GET_ORDER_HISTORY = createAsyncAction(
  ActionTypes.GET_ORDER_HISTORY_REQUEST,
  ActionTypes.GET_ORDER_HISTORY_SUCCESS,
  ActionTypes.GET_ORDER_HISTORY_FAILURE
)<string, any, string>();

export const getOrderHistoryEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ORDER_HISTORY.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_ORDER_HISTORY, action.payload)).pipe(
        mergeMap((response) => of(GET_ORDER_HISTORY.success(response))),
        catchError((error) => of(GET_ORDER_HISTORY.failure(error)))
      )
    )
  );

const GET_ORDER_DETAIL = createAsyncAction(
  ActionTypes.GET_ORDER_DETAIL_REQUEST,
  ActionTypes.GET_ORDER_DETAIL_SUCCESS,
  ActionTypes.GET_ORDER_HISTORY_FAILURE
)<string, any, string>();

export const getOrderDetailEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ORDER_DETAIL.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_ORDER_DETAIL, action.payload)).pipe(
        mergeMap((response) => of(GET_ORDER_DETAIL.success(response))),
        catchError((error) => of(GET_ORDER_DETAIL.failure(error)))
      )
    )
  );
const GET_PAGE_DETAIL = createAsyncAction(
  ActionTypes.GET_PAGE_DETAIL_REQUEST,
  ActionTypes.GET_PAGE_DETAIL_SUCCESS,
  ActionTypes.GET_PAGE_DETAIL_FAILURE
)<string, any, string>();

export const getPageDetailEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_PAGE_DETAIL.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_PAGE_DETAIL, action.payload)).pipe(
        mergeMap((response) => of(GET_PAGE_DETAIL.success(response))),
        catchError((error) => of(GET_PAGE_DETAIL.failure(error)))
      )
    )
  );
const ADD_PRODUCT_WISHLIST = createAsyncAction(
  ActionTypes.ADD_PRODUCT_WISHLIST_REQUEST,
  ActionTypes.ADD_PRODUCT_WISHLIST_SUCCESS,
  ActionTypes.ADD_PRODUCT_WISHLIST_FAILURE
)<string, any, string>();

export const addProductToWishlistEpic: Epic = (action$) =>
  action$.pipe(
    ofType(ADD_PRODUCT_WISHLIST.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.ADD_PRODUCT_WISHLIST, action.payload)).pipe(
        mergeMap((response) => of(ADD_PRODUCT_WISHLIST.success(response))),
        catchError((error) => of(ADD_PRODUCT_WISHLIST.failure(error)))
      )
    )
  );
const GET_ALL_WISHLIST_PRODUCT = createAsyncAction(
  ActionTypes.GET_ALL_WISHLIST_PRODUCT_REQUEST,
  ActionTypes.GET_ALL_WISHLIST_PRODUCT_SUCCESS,
  ActionTypes.GET_ALL_WISHLIST_PRODUCT_FAILURE
)<string, any, string>();

export const getAllWishlistProductsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_WISHLIST_PRODUCT.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_ALL_WISHLIST_PRODUCT, action.payload)
      ).pipe(
        mergeMap((response) => of(GET_ALL_WISHLIST_PRODUCT.success(response))),
        catchError((error) => of(GET_ALL_WISHLIST_PRODUCT.failure(error)))
      )
    )
  );
const DELETE_WISHLIST_ITEM = createAsyncAction(
  ActionTypes.DELETE_WISHLIST_ITEM_REQUEST,
  ActionTypes.DELETE_WISHLIST_ITEM_SUCCESS,
  ActionTypes.DELETE_WISHLIST_ITEM_FAILURE
)<string, any, string>();

export const deleteWishlistItemEpic: Epic = (action$) =>
  action$.pipe(
    ofType(DELETE_WISHLIST_ITEM.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.DELETE_WISHLIST_ITEM, action.payload)).pipe(
        mergeMap((response) => of(DELETE_WISHLIST_ITEM.success(response))),
        catchError((error) => of(DELETE_WISHLIST_ITEM.failure(error)))
      )
    )
  );
const GET_ALL_BANNERS = createAsyncAction(
  ActionTypes.GET_ALL_BANNERS_REQUEST,
  ActionTypes.GET_ALL_BANNERS_SUCCESS,
  ActionTypes.GET_ALL_BANNERS_FAILURE
)<string, any, string>();

export const getAllBannersEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_BANNERS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_ALL_BANNERS, action.payload)).pipe(
        mergeMap((response) => of(GET_ALL_BANNERS.success(response))),
        catchError((error) => of(GET_ALL_BANNERS.failure(error)))
      )
    )
  );
const SEND_ENQUIRY = createAsyncAction(
  ActionTypes.SEND_ENQUIERY_REQUEST,
  ActionTypes.SEND_ENQUIERY_SUCCESS,
  ActionTypes.SEND_ENQUIERY_FAILURE
)<string, any, string>();

export const sendEnquiryEpic: Epic = (action$) =>
  action$.pipe(
    ofType(SEND_ENQUIRY.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.SEND_ENQUIRY, action.payload)).pipe(
        mergeMap((response) => of(SEND_ENQUIRY.success(response))),
        catchError((error) => of(SEND_ENQUIRY.failure(error)))
      )
    )
  );
const GET_ALL_BLOGS = createAsyncAction(
  ActionTypes.GET_ALL_BLOGS_REQUEST,
  ActionTypes.GET_ALL_BLOGS_SUCCESS,
  ActionTypes.GET_ALL_BLOGS_FAILURE
)<string, any, string>();

export const getAllBlogsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_BLOGS.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_ALL_BLOGS, action.payload)).pipe(
        mergeMap((response) => of(GET_ALL_BLOGS.success(response))),
        catchError((error) => of(GET_ALL_BLOGS.failure(error)))
      )
    )
  );
const GET_BLOG_DETAIL = createAsyncAction(
  ActionTypes.GET_BLOG_DETAIL_REQUEST,
  ActionTypes.GET_BLOG_DETAIL_SUCCESS,
  ActionTypes.GET_BLOG_DETAIL_FAILURE
)<string, any, string>();

export const getBlogDetailEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_BLOG_DETAIL.request),
    mergeMap((action) =>
      from(API.post(APIEndpoints.GET_BLOG_DETAIL, action.payload)).pipe(
        mergeMap((response) => of(GET_BLOG_DETAIL.success(response))),
        catchError((error) => of(GET_BLOG_DETAIL.failure(error)))
      )
    )
  );
const GET_ALL_MEGA_MENUS = createAsyncAction(
  ActionTypes.GET_ALL_MEGA_MENUS_REQUEST,
  ActionTypes.GET_ALL_MEGA_MENUS_SUCCESS,
  ActionTypes.GET_ALL_MEGA_MENUS_FAILURE
)<string, any, string>();

export const getAllMegaMenuEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_MEGA_MENUS.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_ALL_MEGA_MENUS, action.payload, {
          headers: {
            StoreId: action.payload?.StoreId,
            "Cache-Control": "max-age=3600",
          },
        })
      ).pipe(
        mergeMap((response) => of(GET_ALL_MEGA_MENUS.success(response))),
        catchError((error) => of(GET_ALL_MEGA_MENUS.failure(error)))
      )
    )
  );
