/** @format */

import {
  CaretRightOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Collapse,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Select,
  Space,
  Tabs,
  theme,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { AiOutlinePhone } from "react-icons/ai";
import Loadable from "react-loadable";
import BeatLoader from "react-spinners/BeatLoader";
import { toStringAsFixed } from "../../Helpers/FrontendHelper";
import CustomBreadcrumb from "../../components/custom_breadcrump/CustomBreadcrumb";
import ProductSelectedAttribute from "../../components/product_selected_attribute/ProductSelectedAttribute";
import ActionTypes from "../../constants/action_types";
import {
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
  GOOGLE_ADDRESS_API_KEY,
} from "../../constants/definitions";
import Routes_Constants from "../../constants/routes_configs";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  CartProduct,
  Country,
} from "../../redux/reducers/CommonReducer/CommonReducerInterface";
import {
  homePageDataModel,
  OrderType,
  PaymentMethod,
} from "../../redux/reducers/HomePageReducer/HomePageReducerInterface";
import { DeliveryAddress } from "../../redux/reducers/MakePaymentReducer/MakePaymentInterface";
import {
  MakePaymentFormValues,
  MakePaymentRequest,
} from "../../types/commonInterfaces";
import LazyImage from "../../utils/Lazy Image/LazyImage";
import isMobile from "../../utils/isMobile";
import { useApiCalls } from "../../utils/useApiCalls";
import { useRetailHelper } from "../../utils/useRetailHelper";
import { validateNumber } from "../../utils/validators";
import OrderRecieverInfo from "./OrderRecieverInfo";
import Paypal from "./Paypal";
import TransactionModal from "./TransactionModal";

const { TextArea } = Input;

const CheckoutLoadable = Loadable({
  loader: () =>
    import("../../containers/checkout_container/checkout_container"),
  loading: () => null,
});

interface MakePaymentProps {
  voucherCode: string | undefined;
  setVoucherCode: Dispatch<SetStateAction<string | undefined>>;
  onRedeemVoucherHandler: () => void;
  makePaymentForm: FormInstance<MakePaymentFormValues>;
  onMakePaymentFormSubmitHandler: (values: MakePaymentFormValues) => void;
  homePageDataModel: homePageDataModel | undefined;
  activeOrderType: OrderType | undefined;
  setActiveOrderType: Dispatch<SetStateAction<OrderType | undefined>>;
  activePaymentMethod: PaymentMethod | undefined;
  setActivePaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
  isLoading: boolean;
  isCheckVoucherSuccess: boolean | undefined;
  voucherDiscount: string | undefined;
  deliveryAddress: any;
  setDeliveryAddress: any;
  onAddDeliveryLocationSubmit: () => void;
  addDeliverLocationLoading: boolean | undefined;
  deliveryAddresses: DeliveryAddress[] | undefined;
  makePaymentLoading: boolean | undefined;
  isMakePaymentSuccessful: boolean | undefined;
  selectedDate: string;
  selectedWeekDay: string;
  setSelectedWeekDay: Dispatch<SetStateAction<string>>;
  finalOrderNote: string;
  setFinalOrderNote: Dispatch<SetStateAction<string>>;
  isGuestCheckout: boolean;
  countries?: Country[];
  cardElement?: any;
  stripeLoading: boolean;
  getMakePaymentRequest: () => Promise<MakePaymentRequest | undefined>;
}

const MakePayment: React.FC<MakePaymentProps> = ({
  voucherCode,
  setVoucherCode,
  onRedeemVoucherHandler,
  makePaymentForm,
  onMakePaymentFormSubmitHandler,
  homePageDataModel,
  activeOrderType,
  setActiveOrderType,
  activePaymentMethod,
  setActivePaymentMethod,
  isLoading,
  isCheckVoucherSuccess,
  voucherDiscount,
  deliveryAddress,
  setDeliveryAddress,
  onAddDeliveryLocationSubmit,
  addDeliverLocationLoading,
  deliveryAddresses,
  makePaymentLoading,
  isMakePaymentSuccessful,
  selectedDate,
  selectedWeekDay,
  finalOrderNote,
  setSelectedWeekDay,
  setFinalOrderNote,
  isGuestCheckout,
  countries,
  cardElement,
  stripeLoading,
  getMakePaymentRequest,
}) => {
  const {
    getCartProducts,
    getTotalAmountOfCart,
    calculateTotalTax,
    getTotalAmountWithTaxOfCart,
    currencySymbol,
    isStoreTaxInclusive,
    handleNavigation,
    getVoucherDiscountAmount,
    getDeliveryAmount,
    getDeliveryTax,
    getDiscountTax,
    totalPriceOfModifiers,
  } = useRetailHelper();
  const { getProductDetailBySlug } = useApiCalls();
  const dispatch = useAppDispatch();
  const { loginResponse } = useAppSelector((state) => state.authentication);
  const { getTimeArray, getDefaultSelectedCountry, getPickupDeliveryMessage } =
    useRetailHelper();
  const { token } = theme.useToken();
  const [activeKey, setActiveKey] = useState<string[] | string>([]);

  return (
    <>
      <TransactionModal isGuestCheckout={isGuestCheckout} />
      <section className="pb-50 mt-4">
        <div className="checkoutpage">
          <div className="container">
            <CustomBreadcrumb items={[{ title: "Checkout", link: "" }]} />
            <div className="row g-4">
              <div className="col-lg-8 ">
                <div className="card card-body h-100">
                  <Form
                    form={makePaymentForm}
                    onFinish={onMakePaymentFormSubmitHandler}
                    labelAlign="left"
                    labelCol={{
                      span: 24,
                    }}
                    fields={[
                      {
                        name: ["recieverCountryId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["recieverCountryPhoneNumberPrefixId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["countryId"],
                        value: getDefaultSelectedCountry(),
                      },
                      {
                        name: ["countryPhoneNumberPrefixId"],
                        value: getDefaultSelectedCountry(),
                      },
                    ]}
                    name="normal_login"
                    className="col-md-12 row login-form"
                  >
                    <div className="mb-3 ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-md-3 d-none d-md-block">
                          <h4>Make Payment</h4>
                        </div>
                        <div className="col-md-9">
                          <Tabs
                            activeKey={activeOrderType?.id}
                            items={homePageDataModel?.storeDetails?.orderTypes.map(
                              (item, index) => {
                                return {
                                  key: item.id,
                                  label: item.displayName,
                                };
                              }
                            )}
                            onChange={(e) => {
                              setActiveOrderType(
                                homePageDataModel?.storeDetails?.orderTypes?.find(
                                  (item) => item.id == e
                                )
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {activeOrderType?.identifier == "2" && (
                      <div className="ms-2">
                        {getPickupDeliveryMessage(
                          activeOrderType?.identifier,
                          selectedWeekDay
                        ) && (
                          <Alert
                            message={getPickupDeliveryMessage(
                              activeOrderType?.identifier,
                              selectedWeekDay
                            )}
                            type="warning"
                            className="mb-3"
                          />
                        )}

                        <a className="fw-bold text-theme">
                          <i className="fas fa-credit-card fs-lg me-2 mt-n1 align-middle" />
                          Delivery Address
                        </a>
                        <div className="accordion-body mt-3">
                          <div className="credit-card-form row">
                            <div className="mb-3 col-md-5">
                              <GooglePlacesAutocomplete
                                apiKey={GOOGLE_ADDRESS_API_KEY}
                                debounce={800}
                                selectProps={{
                                  placeholder: "Choose Your Location",
                                  onChange: (val) => {
                                    const addressLabel = val?.label || "";

                                    geocodeByAddress(addressLabel)
                                      .then((results) => getLatLng(results[0]))
                                      .then(({ lat, lng }) => {
                                        const googleGeocodeApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_ADDRESS_API_KEY}`;
                                        fetch(googleGeocodeApiUrl)
                                          .then((response) => response.json())
                                          .then((data) => {
                                            const postalCodeResult =
                                              data.results[0].address_components.find(
                                                (component: any) =>
                                                  component.types.includes(
                                                    "postal_code"
                                                  )
                                              );
                                            if (postalCodeResult) {
                                              const postalCode =
                                                postalCodeResult.long_name;
                                              setDeliveryAddress({
                                                id: "",
                                                latitude: lat,
                                                longitude: lng,
                                                deliveryLocation:
                                                  addressLabel +
                                                  " " +
                                                  postalCode,
                                              });
                                            }
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error fetching postal code:",
                                              error
                                            );
                                          });
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error geocoding:",
                                          error
                                        );
                                      });
                                  },
                                }}
                              />
                            </div>
                            {/* 
                            <div className="mb-3 col-md-5">
                              <Button
                                danger
                                onClick={() => {
                                  setDeliveryAddressValue(undefined);
                                  setDeliveryAddress({
                                    id: "",
                                    latitude: "",
                                    longitude: "",
                                    deliveryLocation: "",
                                  });
                                }}
                              >
                                Clear Location
                              </Button>
                            </div> */}
                          </div>
                          {deliveryAddresses?.length == 0 && (
                            <small className="text-muted mb-2">
                              You Haven't Added Any Delivery Location. Your
                              Added Locations Will Appear Here.
                            </small>
                          )}

                          <div>
                            <Space direction="vertical">
                              {(() => {
                                const uniqueLocations = new Set();

                                return deliveryAddresses
                                  ?.filter((item) => {
                                    const isUnique = !uniqueLocations.has(
                                      item.deliveryLocation
                                    );

                                    if (isUnique) {
                                      uniqueLocations.add(
                                        item.deliveryLocation
                                      );
                                    }

                                    return isUnique;
                                  })
                                  ?.map((item, index) => {
                                    return (
                                      <Radio
                                        onChange={(e) => {
                                          const checkedDeliveryAddress =
                                            e.target.value;
                                          setDeliveryAddress(
                                            checkedDeliveryAddress
                                          );
                                          dispatch({
                                            type: ActionTypes.ADD_DELIVERY_LOCATION_REQUEST,
                                            payload: {
                                              Id:
                                                checkedDeliveryAddress.id ?? "",
                                              UserId: loginResponse?.userId,
                                              StoreId:
                                                homePageDataModel?.storeDetails
                                                  ?.id,
                                              Latitude: String(
                                                checkedDeliveryAddress.latitude
                                              ),
                                              Longitude: String(
                                                checkedDeliveryAddress.longitude
                                              ),
                                              DeliveryLocation: String(
                                                checkedDeliveryAddress.deliveryLocation
                                              ),
                                            },
                                          });
                                        }}
                                        key={item.id}
                                        value={item}
                                        checked={
                                          deliveryAddress.deliveryLocation ==
                                          item.deliveryLocation
                                        }
                                      >
                                        {item.deliveryLocation}
                                      </Radio>
                                    );
                                  });
                              })()}
                            </Space>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeOrderType?.identifier != "2" &&
                      getPickupDeliveryMessage(
                        activeOrderType?.identifier ?? "",
                        selectedWeekDay
                      ) && (
                        <div className="">
                          <Alert
                            message={getPickupDeliveryMessage(
                              activeOrderType?.identifier ?? "",
                              selectedWeekDay
                            )}
                            type="warning"
                            className="mb-3"
                          />
                        </div>
                      )}

                    <div className="row w-100 mt-3">
                      <div className=" col-md-6">
                        <a className="fw-bold text-theme">
                          <i className="fas fa-calendar-alt fs-lg me-2 mt-n1 align-middle" />
                          {activeOrderType?.displayName} Date And Time
                        </a>
                        <div className="accordion-body mt-3">
                          <div className="credit-card-form row">
                            <div
                              className={
                                activeOrderType?.identifier == "2"
                                  ? "col-sm-12"
                                  : "col-sm-6"
                              }
                            >
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: EMPTY_FIELD_MESSAGE,
                                  },
                                ]}
                                name="pickUpDeliveryDateTime"
                              >
                                <DatePicker
                                  className="w-100"
                                  disabledDate={(current) => {
                                    // Disable dates before today
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  }}
                                  onChange={(date) => {
                                    // setSelectedDate(date);
                                    // Extract the selected weekday
                                    const choosedWeekday = dayjs(
                                      date,
                                      "dddd"
                                    ).format("dddd");
                                    setSelectedWeekDay(
                                      choosedWeekday.toLowerCase()
                                    );
                                  }}
                                />
                              </Form.Item>
                            </div>
                            {activeOrderType?.identifier != "2" && (
                              <div className="col-sm-6">
                                <Form.Item
                                  // label={`${activeOrderType?.displayName} Time`}
                                  name="time"
                                  rules={[
                                    {
                                      required:
                                        activeOrderType?.identifier != "2" &&
                                        true,
                                      message: `Please input your ${activeOrderType?.displayName?.toLowerCase()} time!`,
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={!selectedWeekDay}
                                    options={getTimeArray(
                                      activeOrderType?.identifier
                                        ? activeOrderType?.identifier
                                        : "",
                                      selectedWeekDay
                                    )}
                                    placeholder="Select Time"
                                  />
                                </Form.Item>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-6">
                        <a className="fw-bold text-theme mb-2">
                          <i className="fas fa-file-invoice-dollar me-2"></i>
                          Apply Voucher Code
                        </a>
                        <div className="accordion-body needs-validation row mt-3">
                          {isCheckVoucherSuccess ? (
                            <>
                              <div className="mb-3 col-md-9">
                                <Alert
                                  className="alert-checkout"
                                  style={{ justifySelf: "flex-end" }}
                                  message={
                                    <>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              marginRight: "3px",
                                              color: "red",
                                            }}
                                          >
                                            {voucherCode}
                                          </span>
                                          <span style={{ color: "#444" }}>
                                            applied !
                                          </span>
                                        </div>
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#F03F4C",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            dispatch({
                                              type: ActionTypes.REMOVE_CHECK_VOUCHER,
                                            });
                                          }}
                                        >
                                          Remove{" "}
                                        </span>
                                      </div>
                                    </>
                                  }
                                  description={
                                    <span style={{ color: "#444" }}>
                                      {voucherDiscount} % Off
                                    </span>
                                  }
                                  type="success"
                                  showIcon
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="mb-3 col-md-9">
                                <Input
                                  onPressEnter={(e) => {
                                    e.preventDefault();
                                  }}
                                  value={voucherCode}
                                  onChange={(e) => {
                                    setVoucherCode(e.target.value);
                                  }}
                                  placeholder="Enter Voucher Code"
                                />
                              </div>
                              <div className="mb-3 col-md-2">
                                <Button
                                  type="default"
                                  onClick={() => {
                                    onRedeemVoucherHandler();
                                  }}
                                  loading={isLoading}
                                >
                                  {isLoading && (
                                    <BeatLoader
                                      loading={true}
                                      size={8}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                      className="me-2 primary-color-loader"
                                    />
                                  )}
                                  {isLoading ? "Applying..." : "Apply"}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {isGuestCheckout && (
                      <div className="ms-2 mt-1 mb-2  p-3 border">
                        <a className="fw-bold">
                          <i className="fas fa-user fs-lg me-2 mt-n1 align-middle" />
                          User Information
                        </a>
                        <div className="accordion-body mt-3">
                          <div className="credit-card-form row">
                            <div className="col-sm-6">
                              <Form.Item
                                label="Full Name"
                                name="fullName"
                                rules={[
                                  {
                                    required: true,
                                    message: EMPTY_FIELD_MESSAGE,
                                  },
                                ]}
                              >
                                <Input
                                  prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                  }
                                  placeholder="Please Enter Full Name"
                                />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6">
                              <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: EMPTY_FIELD_MESSAGE,
                                  },
                                  {
                                    type: "email",
                                    message: EMAIL_VALIDATION_MESSAGE,
                                  },
                                ]}
                              >
                                <Input
                                  // readOnly
                                  prefix={
                                    <MailOutlined className="site-form-item-icon" />
                                  }
                                  placeholder="Please Enter Email"
                                />
                              </Form.Item>
                            </div>

                            <div className="mb-3 col-sm-6">
                              <Form.Item
                                label="Phone"
                                name="phoneNumber"
                                rules={[
                                  {
                                    required: true,
                                    message: EMPTY_FIELD_MESSAGE,
                                  },
                                  {
                                    validator: validateNumber,
                                  },
                                ]}
                              >
                                <Input
                                  addonBefore={
                                    <Form.Item
                                      name="countryPhoneNumberPrefixId"
                                      noStyle
                                    >
                                      <Select
                                        style={{
                                          width: 120,
                                        }}
                                        placeholder="prefix"
                                      >
                                        {countries &&
                                          countries?.map((item) => (
                                            <Select.Option
                                              value={item.id}
                                              key={item.id}
                                            >
                                              {" "}
                                              <span
                                                style={{ fontSize: "14px" }}
                                              >
                                                {item.countryPhoneNumberPrefix}
                                              </span>
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  }
                                  prefix={
                                    <AiOutlinePhone className="site-form-item-icon" />
                                  }
                                  placeholder="Please Enter Phone"
                                />
                              </Form.Item>
                            </div>
                            <div className="mb-3 col-sm-3">
                              <Form.Item
                                label="Country"
                                name="countryId"
                                rules={[
                                  {
                                    required: true,
                                    message: EMPTY_FIELD_MESSAGE,
                                  },
                                ]}
                              >
                                <Select placeholder="Please Choose Country">
                                  {countries &&
                                    countries?.map((item) => (
                                      <Select.Option
                                        value={item.id}
                                        key={item.id}
                                      >
                                        {" "}
                                        <span style={{ fontSize: "14px" }}>
                                          <img
                                            src={item.imageUrl}
                                            style={{
                                              height: "20px",
                                              width: "30px",
                                              marginRight: "4px",
                                            }}
                                          />
                                          {item.value}
                                        </span>
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="mb-3 col-sm-3">
                              <Form.Item label="Postal Code" name="postalCode">
                                <Input placeholder="Please Enter Postal Code" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {homePageDataModel?.storeDetails
                      ?.enableOrderGiftReceiverForm && (
                      <div className="mb-4 mt-2 p-0">
                        <Collapse
                          collapsible={"disabled"}
                          defaultActiveKey={["1"]}
                          onChange={(keys) => {
                            setActiveKey(keys);
                          }}
                          bordered={false}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                          items={[
                            {
                              key: "1",
                              label: (
                                <p className="fw-bold">
                                  If you'd like to send this gift to someone
                                  else, please provide their information.
                                </p>
                              ),
                              children: (
                                <OrderRecieverInfo
                                  isActive={activeKey.includes("1")}
                                />
                              ),
                              style: {
                                marginBottom: 5,
                                background: token.colorWhite,
                                borderRadius: token.borderRadiusLG,
                              },
                              showArrow: false,
                            },
                          ]}
                        />
                      </div>
                    )}

                    <div className="ms-2 border rounder-corner  p-4">
                      <a className="fw-bold text-theme fs-5">
                        <i className="fas fa-credit-card fs-lg me-2 mt-n1 align-middle" />
                        Payment
                      </a>
                      <Collapse
                        accordion={true}
                        activeKey={activePaymentMethod?.identifier}
                        onChange={async (e) => {
                          setActivePaymentMethod(
                            homePageDataModel?.storeDetails?.paymentMethods?.find(
                              (item) => item.identifier == e
                            )
                          );
                        }}
                        className="payment-method-collapse mt-4"
                        items={homePageDataModel?.storeDetails?.paymentMethods?.map(
                          (paymentMethod, index) => {
                            return {
                              key: paymentMethod.identifier,
                              label: (
                                <div className="d-flex align-items-center">
                                  {" "}
                                  <span
                                    className="ms-2 "
                                    style={{ fontSize: "15px" }}
                                  >
                                    {" "}
                                    Pay with {paymentMethod.name}
                                  </span>
                                </div>
                              ),

                              children: (
                                <>
                                  {" "}
                                  {paymentMethod.identifier == "7" ? (
                                    <>
                                      <p className="fs-sm mb-3 text-muted">
                                        We accept credit cards:&nbsp;&nbsp;
                                        <img
                                          className="d-inline-block align-middle"
                                          src="assets/images/card (1).png"
                                          style={{ width: 186 }}
                                          alt="Cerdit Cards"
                                        />
                                      </p>
                                      {cardElement}
                                      <div className="col-md-12 mt-3">
                                        <div className="d-flex gap-3 justify-content-between">
                                          {!isMobile && (
                                            <Button
                                              onClick={() => {
                                                handleNavigation(
                                                  Routes_Constants.CHECKOUT,
                                                  CheckoutLoadable
                                                );
                                              }}
                                              type="default"
                                              className="d-flex align-items-center justify-content-center gap-2  px-5"
                                            >
                                              <i className="fas fa-shopping-cart me-2 fs-base align-middle" />
                                              Back to cart
                                            </Button>
                                          )}

                                          <Button
                                            disabled={
                                              getCartProducts().length == 0
                                            }
                                            htmlType="submit"
                                            type="primary"
                                            className="d-flex align-items-center justify-content-center gap-2  px-5"
                                          >
                                            {stripeLoading && (
                                              <BeatLoader
                                                loading={true}
                                                color="white"
                                                size={8}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                              />
                                            )}
                                            Pay with Credit Card
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="">
                                      <Paypal
                                        makePaymentForm={makePaymentForm}
                                        getMakePaymentRequest={
                                          getMakePaymentRequest
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              ),
                            };
                          }
                        )}
                      />
                    </div>
                  </Form>
                </div>
              </div>
              <section
                className="fixed-bottom bottom-30 bg-white p-3 d-md-none"
                style={{}}
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6 col-6">
                      <div className="totalprice">
                        <p className="fw-bold fs-4">
                          Total:
                          <span className="text-red1 ms-2">
                            {" "}
                            {currencySymbol()} {getTotalAmountWithTaxOfCart()}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="d-flex d-lg-flex ">
                        <div className="w-100 pe-3">
                          <Button
                            type="default"
                            className=" d-block w-100"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#navbar-default"
                          >
                            <span className="d-inline d-sm-none">
                              My Orders
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="col-lg-4 ">
                <div className="sticky-md-top" style={{ top: 130, zIndex: 1 }}>
                  <div
                    className="bg-white card rounded-3 p-2 ms-lg-auto ordersummary offcanvas offcanvas-start"
                    id="navbar-default "
                  >
                    <div className="d-flex justify-content-end align-items-center mb-2 d-block d-lg-none">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="py-2 px-xl-2 ">
                      <div className="widget mb-3">
                        <h2 className="widget-title text-center fw-bold">
                          Order summary
                        </h2>
                        {homePageDataModel?.storeDetails
                          ?.freeShippingDiscountAmountLimit &&
                          activeOrderType?.identifier == "2" && (
                            <Alert
                              message={`${homePageDataModel.storeDetails.freeDeliveryMessage}`}
                              type="warning"
                              className="mb-3"
                            />
                          )}
                        <div style={{ height: "23em", overflowY: "auto" }}>
                          {getCartProducts().map(
                            (product: CartProduct, index) => {
                              return (
                                <div className="mb-1" key={index}>
                                  <div className="d-flex align-items-center pb-1">
                                    <a
                                      className="d-block flex-shrink-0"
                                      onClick={() => {
                                        getProductDetailBySlug(product.slug);
                                        dispatch({
                                          type: ActionTypes.SET_ACTIVE_SLUG,
                                          payload: product.slug,
                                        });
                                      }}
                                    >
                                      <LazyImage
                                        src={product.imageUrl}
                                        width={64}
                                        alt="Product"
                                      />
                                    </a>
                                    <div className="ps-2">
                                      <h6 className="widget-product-title">
                                        <a
                                          onClick={() => {
                                            getProductDetailBySlug(
                                              product.slug
                                            );
                                            dispatch({
                                              type: ActionTypes.SET_ACTIVE_SLUG,
                                              payload: product.slug,
                                            });
                                          }}
                                        >
                                          {product.name}
                                        </a>
                                      </h6>
                                      <div className="widget-product-meta">
                                        <span className="text-accent me-2">
                                          {currencySymbol()}{" "}
                                          {(
                                            product.price +
                                            totalPriceOfModifiers(
                                              product?.priceModifiers ?? []
                                            )
                                          ).toFixed(2)}
                                        </span>
                                        <span className="text-muted">
                                          x {product.quantity}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <ProductSelectedAttribute product={product} />
                                  {index < getCartProducts().length - 1 && (
                                    <Divider style={{ margin: "0.8rem 0" }} />
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <TextArea
                        value={finalOrderNote}
                        onChange={(e) => {
                          setFinalOrderNote(e.target.value);
                        }}
                        placeholder="Add any special instructions or notes for your order here."
                        className="mb-2"
                      />
                      <ul className="list-unstyled fs-sm pb-2 border-bottom">
                        {getVoucherDiscountAmount() > 0 && (
                          <li className="d-flex justify-content-between align-items-center">
                            <span className="me-2">Discount:</span>
                            <span className="text-end">
                              - {currencySymbol()}{" "}
                              {toStringAsFixed(getVoucherDiscountAmount())}
                            </span>
                          </li>
                        )}
                        {activeOrderType?.identifier == "2" && (
                          <li className="d-flex justify-content-between align-items-center">
                            <span className="me-2">Delivery Amount:</span>
                            <span className="text-end">
                              {currencySymbol()} {getDeliveryAmount()}
                            </span>
                          </li>
                        )}
                        <li className="d-flex justify-content-between align-items-center">
                          <span className="me-2">Subtotal:</span>
                          <span className="text-end">
                            {currencySymbol()} {getTotalAmountOfCart()}
                          </span>
                        </li>

                        <li className="d-flex justify-content-between align-items-center">
                          <span className="me-2">
                            {" "}
                            {isStoreTaxInclusive()
                              ? "Total Tax Included:"
                              : "Total Tax"}
                          </span>
                          <span className="text-end">
                            {currencySymbol()}{" "}
                            {toStringAsFixed(
                              calculateTotalTax() +
                                getDeliveryTax() -
                                getDiscountTax()
                            )}
                          </span>
                        </li>
                      </ul>
                      <h3 className="fw-normal text-center my-4 fw-bold">
                        {currencySymbol()} {getTotalAmountWithTaxOfCart()}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MakePayment;
