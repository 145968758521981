/** @format */

import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from "@paypal/paypal-js";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { FormInstance } from "antd";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { API } from "../../Helpers/BaseUrlProvider";
import ActionTypes from "../../constants/action_types";
import APIEndpoints from "../../constants/api_endpoints";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  MakePaymentFormValues,
  MakePaymentRequest,
} from "../../types/commonInterfaces";

interface PaypalInterface {
  getMakePaymentRequest: () => Promise<MakePaymentRequest | undefined>;
  makePaymentForm: FormInstance<MakePaymentFormValues>;
}

const Paypal: React.FC<PaypalInterface> = ({
  getMakePaymentRequest,
  makePaymentForm,
}) => {
  const [{ options, isPending }] = usePayPalScriptReducer();
  const dispatch = useAppDispatch();
  const { validateOrderLoading } = useAppSelector((state) => state.makePayment);
  const [formValidationError, setFormValidationError] = useState<string | null>(
    null
  );

  const validateFormAndOrder = async () => {
    try {
      const payRequest = await getMakePaymentRequest();
      setFormValidationError(null);
      try {
        await makePaymentForm.validateFields();
      } catch (error) {
        setFormValidationError(
          "Form validation failed. Please fill out all required fields above."
        );
        return false;
      }

      try {
        await API.post(APIEndpoints.VALIDATE_ORDER, payRequest);
      } catch (apiError: any) {
        const errorMessage =
          apiError.response?.data?.message?.[0]?.message ||
          "Order validation failed. Please try again.";
        setFormValidationError(errorMessage);
        return false;
      }

      setFormValidationError(null);
      return true;
    } catch (generalError) {
      setFormValidationError("An unexpected error occurred. Please try again.");
      return false;
    }
  };
  const onCreateOrder = async (
    data: CreateOrderData,
    actions: CreateOrderActions
  ): Promise<string> => {
    const payRequest = await getMakePaymentRequest();
    if (await validateFormAndOrder()) {
      return actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {
            amount: {
              value: payRequest?.TotalAmount || "0.00",
              currency_code: "USD",
            },
          },
        ],
      });
    } else {
      return Promise.reject(new Error("Form validation failed"));
    }
  };

  const onApproveOrder = async (
    data: OnApproveData,
    actions: OnApproveActions
  ): Promise<void> => {
    if (!actions.order) {
      return Promise.reject(new Error("Order action is not available"));
    }

    try {
      const details = await actions.order.capture();
      // console.log("Payment response:", details);
      setFormValidationError(null);
      const payRequest = await getMakePaymentRequest();

      if (payRequest) {
        dispatch({
          type: ActionTypes.MAKE_PAYMENT_REQUEST,
          payload: { ...payRequest, PaymentIntentId: details.id },
        });
      }
    } catch (error) {
      // console.error("Failed to approve order:", error);
    }
  };
  return (
    <div className="paypal-container">
      {/* Custom Header and Description */}
      <div className="paypal-header">
        <h2 className="paypal-title">Complete Your Payment</h2>
        <p className="paypal-description">
          You’re almost there! Review your order and proceed to pay securely
          with PayPal.
        </p>
      </div>

      {/* Payment Icons */}
      <div className="text-center">
        {" "}
        <p className="fs-sm mb-3 text-muted">
          We accept credit cards:&nbsp;&nbsp;
          <img
            className="d-inline-block align-middle"
            src="assets/images/card (1).png"
            style={{ width: 186 }}
            alt="Cerdit Cards"
          />
        </p>
      </div>

      {/* Card-Like Styling and Loading Spinner */}
      <div className={`payment-card ${validateOrderLoading ? "loading" : ""}`}>
        {validateOrderLoading ? (
          <div className="loading-spinner">
            <BeatLoader loading={true} color="#000" size={15} />
            <p>
              Your order is being validated before proceeding with the payment.
              Please wait...
            </p>
          </div>
        ) : (
          <>
            {/* Error Message */}
            {formValidationError && (
              <Alert variant="warning" className="form-error-alert">
                {formValidationError}
              </Alert>
            )}

            {/* PayPal Button */}
            <PayPalButtons
              className={`paypal-button ${formValidationError ? "mt-3" : ""}`}
              fundingSource="paypal"
              style={{
                layout: "vertical",
                color: "gold",
                label: "pay",
                height: 40,
                tagline: false,
              }}
              createOrder={(data, actions) => onCreateOrder(data, actions)}
              onApprove={(data, actions) => onApproveOrder(data, actions)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Paypal;
