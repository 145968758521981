/** @format */

// import brotli from "brotli";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { Helmet } from "react-helmet";
import useAppSelector from "../../hooks/useAppSelector";
import { getPaypalInstance } from "../../utils/paypal";
import getStripeInstance from "../../utils/stripe";
import LayoutContainer from "../layout/layout_container";
import MakePaymentContainer from "./make_payment_container";

const MakePaymentTest: React.FC = () => {
  const { homePageDataModel } = useAppSelector((state) => state.homePage);
  const publishableKeyStore =
    homePageDataModel?.storeDetails?.paymentMethods?.find(
      (paymentM, index) => paymentM.identifier == "7"
    )?.publishableKey;
  const publishableKeyPaypal =
    homePageDataModel?.storeDetails?.paymentMethods?.find(
      (paymentM, index) => paymentM.identifier == "6"
    )?.publishableKey;

  return (
    <>
      <Helmet>
        <title>{homePageDataModel?.storeDetails?.storeName}</title>
        <meta
          property="og:title"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:description"
          content={homePageDataModel?.storeDetails?.storeName}
        />
        <meta
          property="og:image"
          content={homePageDataModel?.storeDetails?.imageUrl}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <PayPalScriptProvider
        options={getPaypalInstance(publishableKeyPaypal ?? "")}
      >
        <Elements stripe={getStripeInstance(publishableKeyStore ?? "")}>
          <LayoutContainer>
            <MakePaymentContainer />
          </LayoutContainer>
        </Elements>
      </PayPalScriptProvider>
    </>
  );
};

export default MakePaymentTest;
